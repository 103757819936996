import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../common/Spinner';
import CreateBlog from './Add Course';
import { resetCourseState } from '../../../../slices/courseSlice';

const AddBlog = () => {

    const dispatch = useDispatch();
    const {editCourse} = useSelector(state => state.course);

    useEffect(()=>{
        dispatch(resetCourseState());
    },[])

    if(editCourse)
        return <Spinner/>

  return (
    <>
        <CreateBlog/>
    </>
  )
}

export default AddBlog