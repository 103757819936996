import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRegisteredWalkInDrives } from '../../../../services/operations/JobAPI';
import { setLoading } from '../../../../slices/profileSlice';
import { formattedFullDate } from '../../../../utils/dateFormatter';
import { Link } from 'react-router-dom';

const MyRegistrations = () => {
    const dispatch = useDispatch();
    const {token} = useSelector(state => state.auth);
    const [registrations,setRegistrations] = useState([]);

    useEffect(()=>{

        const fetchMyRegistrations = async()=>{
            const result = await fetchAllRegisteredWalkInDrives(token);
            // console.log(result)
            if(result){
                setRegistrations(result);
            }
            dispatch(setLoading(false));
        }

        fetchMyRegistrations();
    
    },[])

  return (
    <div className='w-11/12 max-w-maxContent mx-auto'>
        <p className='p-2 my-4 text-4xl font-semibold'>Registered Walk In Drives</p>
            {
                registrations && registrations.length 
                
                ? <section className="flex flex-col items-stretch my-10 ">
                    {/* List Header */}
                    <div className="hidden w-full h-[30px] md:grid grid-cols-[2fr_2fr_2fr] place-content-center  gap-6 p-3 py-6 border border-richblack-700 text-yellow-50">
                        <p className="text-start px-2">Role</p>
                        <p className="px-2">Scheduled On</p>
                        <p className="px-2 place-self-center">Actions</p>
                    </div>

                    {/* List Items */}
                    {registrations.map((wd, index) => {
                        return (
                            <div
                                key={index}
                                className="w-full grid md:grid-cols-[2fr_2fr_2fr]  gap-4 p-3 border border-richblack-700 "
                            >
                                <div className="w-full my-auto text-richblack-50 px-2 font-bold flex justify-start md:justify-center gap-x-4 flex-wrap">
                                    {
                                        wd?.roles?.map((role, index) => (
                                            <p key={index}>{`${role}`}</p>
                                        ))
                                    }
                                </div>
                                <div className="w-full my-auto text-richblack-100 px-2">
                                    <p>{formattedFullDate(wd?.scheduleDay)}</p>
                                </div>

                                <div className="flex gap-x-2 items-center text-xs text-richblack-50 font-bold md:justify-evenly p-2">
                                    <Link
                                        to={`/walk-in-drives/${wd?.category?.name?.toLowerCase().split(" ").join("-")
                                            .replace(/[^a-zA-Z0-9 -]/g,"")
                                            .replace(/-{2,}/g, "-")
                                            .replace(/^-|-$/g, "")
                                          }/${wd?._id}`} 
                                        className="bg-caribbeangreen-300 hover:bg-caribbeangreen-400 transition-all duration-100 px-4 py-2 rounded-lg text-center"
                                    >
                                       View Details
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </section>
                
                : <p>No Walk In Drives Posted Yet</p>
            }
    </div>
  )
}

export default MyRegistrations