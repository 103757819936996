import React from 'react';
import { ContactUsForm } from '../components/ContactPage/ContactUsForm';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import { ImEarth } from 'react-icons/im';
import { IoMdCall } from 'react-icons/io';
import Footer from '../components/common/Footer';
import contactImg from "../assets/Images/contact.jfif"

export const Contact = () => {
  return (
    <div>
        <div className='mt-3 pt-2 w-11/12 max-w-maxContent mx-auto '>
        
            <div className='mb-20 flex max-md:flex-col justify-around gap-6 md:items-start items-center'>
                {/* left section */}
                <div className='my-4 w-full max-w-[600px] flex flex-col justify-betwee rounded-lg overflow-hidden'>
                    <img src={contactImg} alt="Contact Now" className='w-full h-full object-contain' />
                </div>
        
                {/* form */}
                <div className='flex flex-col justify-center gap-2 max-w-[550px] md:p-6 md:border border-richblack-600 md:scale-95'>
                    <p className='text-4xl text-richblack-5 font-bold'>Got skills? We've got the jobs.</p>
                    <p className='text-lg text-richblack-200 font-bold'>Tell us more about yourself and what you've got in mind.</p>
                    <div className='my-4'>
                        <ContactUsForm/>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer/>
    </div>
  )
}
