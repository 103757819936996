import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { fetchBlogDetails } from '../services/operations/BlogAPI';

const BlogDetail = () => {
  
  const {blogId} = useParams();
  // const location = useLocation();
  // const blogId = location.state?.blogId;

  const [blog,setBlog] = useState({});
  
  useEffect(()=>{
    const setBlogDetails = async () => {
      const data = await fetchBlogDetails(blogId);
      if(data){
        setBlog(data)
      }
    }
    setBlogDetails();
  },[])

  // console.log(blog)

  return (
    <div className=''>
      <div className='relative flex justify-center items-center w-full h-[50vh]'>
        <img src={blog?.featuredImage} className='w-full h-full object-cover opacity-55'/>
        <p className='absolute text-3xl md:text-4xl font-bold w-11/12 md:w-10/12 max-w-[1024px] text-center'>{blog?.title}</p>
      </div>
      <div className='w-11/12 max-w-[1024px] mx-auto py-20 flex flex-col gap-y-4 text-justify text-richblack-25'>
        <p className='text-xl'>{blog?.subtitle}</p>
        {
          blog && blog?.sections && blog?.sections.length && 
          <div className='flex flex-col gap-10 py-10'>
            {
              blog?.sections?.map(sc => (
                <div key={sc?._id} className='border-t-2 border-yellow-25 flex flex-col items-stretch gap-4 py-6'>
                  {
                    sc?.heading &&
                    <p className='text-3xl font-bold pb-6 text-yellow-25'>{sc?.heading}</p>
                  }
                  <pre className='pl text-xl text-wrap font-inter italic'>{`${sc?.description}`}</pre>
                  {/* {
                    sc?.links && sc?.links?.length > 0 &&
                    <div className='flex justify-start items-center gap-3 text-yellow-100'>
                      <p className='font-bold text-lg'>Reated Links</p>
                      {
                        sc?.links?.map((li,id)=>(
                          <a key={id} href={"https://google.com"} className='underline'>Link</a>
                        ))
                      }
                    </div>
                  } */}
                </div>
              ))
            }
          </div>
        }
      </div>
    </div>
  )
}

export default BlogDetail