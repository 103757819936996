import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { HighlightText } from '../components/core/HomePage/HighlightText';
import CTAButton from '../components/core/HomePage/Button'
import Banner from '../assets/Images/banner.mp4';
import Footer from '../components/common/Footer';
import { useDispatch } from 'react-redux';
import { fetchAllJobs, fetchAllWalkInDrives } from '../services/operations/JobAPI';
import { JobSlider } from '../components/core/Catalog/Job_Slider';
import { LearningGrid } from '../components/core/AboutPage/LearningGrid';
import { StatsComponent } from '../components/core/AboutPage/StatsComponent';
import { fetchAllBlogs } from '../services/operations/BlogAPI';
import { BlogCard } from '../components/core/Blog/BlogCard';
import { toDays } from '../utils/dateFormatter';
import { TbCategoryFilled } from "react-icons/tb";
// Import Swiper React components
import { Swiper , SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// Import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { ExploreMore } from '../components/core/HomePage/ExploreMore';
import TimeLineSection from '../components/core/HomePage/TimeLineSection';

export const Home = ({categories,setLoading}) => {

    const dispatch = useDispatch();

    // const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [walkInDrives, setWalkInDrives] = useState([]);
    const [blogs,setBlogs] = useState([]);
    
    const fetchJobs = async()=>{
        // setLoading(true);
        const response = await fetchAllJobs();
        setJobs(response); 
        // setLoading(false);
    }

    const getAllWalkInDrives = async()=>{
        // setLoading(true);

        const result = await fetchAllWalkInDrives();
        if(result)
            setWalkInDrives(result);

        // setLoading(false);
    }

    
    const fetchBlogs = async () => {
        // setLoading(true);
        const data = await fetchAllBlogs();

        if(data){
            setBlogs(data);
        }

        // setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        fetchJobs();
        getAllWalkInDrives();
        fetchBlogs();
        setLoading(false);
    },[])

    // const categories = [
    //     {
    //         name: "Full Stack Development",
    //         img: <FaLaptopCode className='bg-[#E6FFF5] p-3 rounded-full' />,
    //     },
    //     {
    //         name: "Frontend Development",
    //         img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
    //     },
    //     {
    //         name: "Backend Development",
    //         img: <IoBagHandle className='bg-[#FFE9E7] p-3 rounded-full' />,
    //     },
    //     {
    //         name: "Cyber Security",
    //         img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
    //     },
    //     {
    //         name: "Sales and Marketing",
    //         img: <IoMdSettings className='bg-[#FFEEE0] p-3 rounded-full' />,
    //     },
    //     {
    //         name: "ERP Developer",
    //         img: <RiHeadphoneFill className='bg-[#DDEDFF] p-3 rounded-full' />,
    //     },
    //     {
    //         name: "SAAS Developer",
    //         img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
    //     },

    // ]

  return (
    <div className='font-inter'>
        {/* Section 1 */}
        <section className='relative mx-auto md:flex flex-col items-center justify-center w-11/12 max-w-maxContent py-10 pb-20 min-h-[65vh]'>
            <div className='w-full flex justify-center items-center gap-14'>
                <div className='flex flex-col items-start gap-4'>
                    <div className='text-container'>
                        <span className='text-3xl lg:text-4xl font-bold'>
                            With CareerCloudHub
                        </span>
                        <div className="dropping-texts">
                            <div className='grad-blue'>Hire Talent Worldwide</div>
                            <div className='grad-blue'>Global IT Opportunities</div>
                            <div className='grad-blue'>Cross-Border Internships</div>
                            {/* <div className='grad-blue'>Form Great Team</div> */}
                        </div>
                    </div>
                    <div className='text-lg text-richblack-300 font-bold md:w-[90%]'>
                        At CareerCloudHub, we bridge the gap between talented professionals and world-class employers. Whether you’re seeking your dream job or hiring top-tier talent, our AI-powered platform connects opportunities across India, the US, and beyond. 
                    </div>
                    <div className='mt-8 flex gap-7 justify-center items-center'>
                        <CTAButton 
                        toLink={'/signup'}
                        active={true}
                        >
                            LearnMore    
                        </CTAButton>

                        <CTAButton 
                        toLink={'/login'}
                        active={false}>
                            Book a Call
                        </CTAButton>
                    </div>
                </div>
                
                <div className='hidden md:block relative w-full max-w-[550px]'>
                    <div className='absolute -z-10 top-0 bottom-0 left-0 right-0 '></div>
                    <video className='shadow-[10px_10px_0px_0px_#F5F5F5]'
                        muted
                        autoPlay 
                        loop>
                        <source src={Banner} type='video/mp4'/>
                    </video>
                </div>
            </div>
        </section>
        
        <section className='bg-pure-greys-5'>         
            {/* Categories */}
            <div className='w-11/12 max-w-maxContent mx-auto flex flex-col py-10 text-black'>
                <p className='text-3xl font-bold my-2'>
                    Job Categories
                </p>
                {
                    categories && categories?.length && 
                    <div className='grid grid-cols-1 md:grid-cols-3 xls:grid-cols-4 gap-8 py-3'>
                        {
                            categories?.map((ct,id) => (
                                <Link key={id} to={`/job/${
                                    ct?.name?.toLowerCase().split(" ").join("-")
                                    .replace(/[^a-zA-Z0-9 -]/g,"")
                                    .replace(/-{2,}/g, "-")
                                    .replace(/^-|-$/g, "")
                                }`}>
                                    <div className='w-full h-full py-3 px-4 border border-black text-richblack-900 flex justify-center items-center rounded-md gap-4 hover:border-caribbeangreen-400 hover:text-caribbeangreen-400 transition-all duration-200 hover:-translate-y-1 group'>
                                        <p className='text-2xl p-2 rounded-full bg-caribbeangreen-400 bg-opacity-20 group-hover:bg-white' >
                                            <TbCategoryFilled />
                                        </p>
                                        <p className='text-xl font-bold'>{ct?.name}</p>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                }
            </div>
            
            <div className='w-11/12 max-w-maxContent mx-auto' >
                
                {/* paragraphs */}
                <div className='py-10 text-black flexBox items-start max-[720px]:flex-col gap-8'>
                    <p className='text-4xl font-inter font-bold leading-tight lg:w-[45%]'>
                        Expand your Reach 
                        <HighlightText text={' Globally'}/>
                    </p>
                    <div className='flex flex-col justify-between gap-7 lg:w-[45%]'>
                        <p className='font-semibold text-lg'>Whether you are an IT company looking for skilled professionals or a job seeker aiming for international exposure, CareerCloudHub bridge the gap between talent and global employers.</p>
                        <CTAButton
                            active={true}
                            toLink={'/signup'}>
                            Learn More
                        </CTAButton>
                    </div>
                </div>
            </div>

        </section>
            
        <section className='w-11/12 max-w-maxContent flex items-center justify-center mx-auto'>
            <ExploreMore/>
        </section>

        {/* Section 2 */}
        <section className='bg-pure-greys-5 text-richblack-400 select-none'>

            <div className='min-h-[333px] bg_homepage text-white'>
                <div className='w-11/12 max-w-maxContent h-full mx-auto flex flex-col items-center'>
                    <div className='h-[150px]' />
                    <div className='flexBox'>
                        <CTAButton 
                            active={true}
                            toLink={'/signup'}>
                            <div className='flexBox gap-2'>
                                Explore All Jobs
                                <FaArrowRight/>
                        </div>
                        </CTAButton>
                        <CTAButton 
                            active={false}
                            toLink={'/signup'}>
                            Learn More
                        </CTAButton>
                    </div>
                </div>
            </div>
            <div className='w-11/12 max-w-maxContent mx-auto'>
                {/* <LearningLanguageSection/> */}
            </div>
        </section>

        {/* Jobs */}
        <section className='pt-8'>
            <div className='w-11/12 max-w-maxContent mx-auto flex flex-col py-8'>
                <p className='text-3xl font-bold my-1'>
                    Find Jobs
                </p>

                {
                    jobs &&
                    <>
                        <JobSlider 
                            jobs={jobs}
                            height={"h-[300px]"}
                            slides={2}
                        />                      
                    </>
                }
            </div>
            
            {/* Walk In Drives */}
            {
                walkInDrives && walkInDrives.length &&
                <div className='w-11/12 max-w-maxContent mx-auto flex py-10 pb-20 flex-col gap-6 px-3'>
                    <p className='text-3xl font-bold'>
                        Explore Walk In Drives
                    </p>

                    {/* Drive List Container */}
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        modules={[Autoplay,FreeMode,Pagination]}
                        autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                        }}
                        breakpoints={{
                        640: {
                            slidesPerView: 2,
                        },
                        980: {
                            slidesPerView: 3,
                        },
                        }}
                        className="grid sm:grid-cols-3 p-2 w-full"
                    >
                        {
                            walkInDrives.map(wd=>(
                                <SwiperSlide key={wd?._id} className='hover:scale-105 transition-all duration-200'>
                                    <Link to={
                                        `/walk-in-drives/${wd?.category?.name?.toLowerCase().split(" ").join("-")
                                            .replace(/[^a-zA-Z0-9 -]/g,"")
                                            .replace(/-{2,}/g, "-")
                                            .replace(/^-|-$/g, "")
                                            }/${wd?._id}`
                                        }
                                        state={{walkInId: wd?._id}}
                                    >
                                        <div className="card example-3">
                                            <div className="inner bg-richblue-900">
                                                <div className={`flex flex-col items-start`}>
                                                    <p className='flex items-center gap-x-2'>
                                                        <span className='text-lg font-bold'>Roles - {wd?.roles[0]}</span>
                                                        {
                                                            wd?.roles?.length > 1 &&
                                                            <span className=' text-yellow-50 text-sm'>+{wd?.roles?.length && wd?.roles?.length - 1} more</span>
                                                        }
                                                    </p>
                                            
                                                    <p>
                                                        <span className='font-bold'>Category - </span>
                                                        <span>{wd?.category?.name}</span>
                                                    </p>
                                            
                                                    {
                                                        toDays(new Date() , new Date(wd?.deadline))
                                                        ? <span className=' text-caribbeangreen-200 text-xs px-2 py-[2px] bg-caribbeangreen-200 bg-opacity-15  border-caribbeangreen-200 border-[.1px] rounded-full mt-2'>
                                                            Registration Closes in {toDays(new Date() , new Date(wd?.deadline))}
                                                            </span>
                                                        : <span className=' text-pink-300 text-xs px-2 py-[2px] bg-pink-300 bg-opacity-15  border-pink-300 border-[.1px] rounded-full mt-2'>
                                                            Registration Closed
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            }

            <div className='bg-richblack-800 '>
                <StatsComponent/>
            </div>

            <div className='w-11/12 max-w-maxContent mx-auto py-20 pt-10 flex flex-col items-center gap-y-8'>
                <p className='text-richblack-5 text-3xl font-bold leading-8'>Checkout Our Blogs</p>
                {
                    (blogs && blogs?.length) &&
                    <div className='w-full flex flex-wrap justify-center items-center gap-8'>
                        {
                            blogs?.slice(0,3)?.map(bg => (
                            <BlogCard key={bg?._id} bg={bg}/>
                            )) 
                        }
                    </div>
                }
            </div>

            {/* Time Line Section */}
            <div className='bg-[#F9F9F9] py-10'>
                <TimeLineSection/>
            </div>
            
            <div className='w-11/12 max-w-maxContent mx-auto pt-16'>
                <LearningGrid/>
            </div>

        </section>
        
        {/* Footer */}
        <Footer/>

    </div>
  )
}
