export const HomePageExplore = [
    {
        tag: 'High-Demand Tech Jobs',
        jobs: [
            {
                title: "Software Engineer",
                description: "Design, develop, and maintain scalable software applications. Strong programming skills in Python, Java, or JavaScript required.",
                experience: '2-5 years',
                openings: 10
            },
            {
                title: "Data Scientist",
                description: "Analyze complex datasets, build predictive models, and extract meaningful business insights using Python, R, and SQL.",
                experience: '2-6 years',
                openings: 8
            },
            {
                title: "Cloud Solutions Architect",
                description: "Develop and optimize cloud infrastructure using AWS, Azure, or Google Cloud to support scalable applications.",
                experience: '3-6 years',
                openings: 5
            },
        ]
    },
    {
        tag: 'Non-Tech & Business Careers',
        jobs: [
            {
                title: "Marketing Manager",
                description: "Develop and execute marketing strategies, manage campaigns, and analyze customer trends to drive business growth.",
                experience: '3-7 years',
                openings: 7
            },
            {
                title: "Financial Analyst",
                description: "Assess financial statements, create reports, and provide investment recommendations based on data-driven insights.",
                experience: '2-5 years',
                openings: 6
            },
            {
                title: "Human Resources Specialist",
                description: "Handle recruitment, employee relations, and HR policies to support organizational success.",
                experience: '1-4 years',
                openings: 5
            },
        ]
    },
    {
        tag: 'Emerging & Future Careers',
        jobs: [
            {
                title: "AI/ML Engineer",
                description: "Build and optimize machine learning models for automation, recommendation systems, and AI-powered solutions.",
                experience: '3-6 years',
                openings: 4
            },
            {
                title: "Sustainability Consultant",
                description: "Help businesses implement eco-friendly practices and develop sustainability strategies.",
                experience: '2-5 years',
                openings: 3
            },
            {
                title: "UX Researcher",
                description: "Study user behavior, conduct usability tests, and provide insights to improve digital product design.",
                experience: '2-5 years',
                openings: 5
            },
        ]
    },
];
