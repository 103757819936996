import React, { useEffect, useState } from 'react'
import { fetchAllBlogs } from '../services/operations/BlogAPI';
import Spinner from '../components/common/Spinner';
import { BlogCard } from '../components/core/Blog/BlogCard';
import Footer from '../components/common/Footer';

const Blogs = () => {

  const [blogs,setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    const fetchBlogs = async () => {
      setLoading(true);
      const data = await fetchAllBlogs();

      if(data){
        setBlogs(data);
      }

      setLoading(false);
    }
    fetchBlogs();
  },[])
  // console.log("Blogs",blogs)
  
  if(loading || !blogs)
    return (<Spinner/>)

  if(!loading && !blogs) {
    return <p>Error 404 Not Found</p>
  }
  
  return (
    <>
      <div className='w-11/12 max-w-maxContent mx-auto flex flex-col gap-y-8 py-16'>
        <p className='text-4xl font-bold'>Explore and Learn</p>
        {
          (blogs && !blogs?.length)
          ? <p className='w-full px-4 mt-8 text-yellow-200 font-medium text-2xl text-center'>
                No Jobs Found for the Selected Category
            </p>
          : <div className='flex max-md:flex-col flex-wrap justify-start items-center gap-6'>
              {
                blogs?.map(bg => (
                  <BlogCard key={bg?._id} bg={bg}/>
                ))
              }
            </div>
        }
      </div>
      <Footer/>
    </>
  )
}

export default Blogs