import "./App.css";
import { useEffect, useState } from "react";
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OpenRoute from "./components/core/Auth/OpenRoute";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import ConfirmationModal from "./components/common/ConfirmationModal";
import { SignUp } from "./pages/SignUp";
import { VerifyEmail } from "./pages/VerifyEmail";
import { Login } from "./pages/Login";
import { ForgotPassword } from "./pages/ForgotPassword";
import { UpdatePassword } from "./pages/UpdatePassword";
import { logout } from './services/operations/authAPI';
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Catalog } from "./pages/Catalog";
import { ApplyJob } from "./pages/ApplyJob";
import { JobDetail } from "./pages/JobDetail";
import { Navbar } from "./components/common/Navbar";
import Dashboard from "./pages/Dashboard";
import RecruiterProfile from "./components/core/Dashboard/Recruiter/RecruiterProfile";
import ReceivedApplications from "./components/core/Dashboard/Recruiter/ReceivedApplications";
import ApplicationDetail from "./pages/ApplicationDetail";
import MyJobs from "./components/core/Dashboard/Recruiter/MyJobs";
import ApplicantProfile from "./components/core/Dashboard/Applicant/ApplicantProfile";
import MyApplications from "./components/core/Dashboard/Applicant/MyApplications";
import Settings from "./components/core/Dashboard/Settings";
import { ACCOUNT_TYPE } from "./utils/constants";
import { EditJob } from "./components/core/Dashboard/Recruiter/EditJob";
import { deleteAccount } from "./services/operations/SettingsAPI";
import Spinner from "./components/common/Spinner";
import CreateJob from "./components/core/Dashboard/Recruiter/CreateJob";
import CreateCategory from "./components/core/Dashboard/Admin/CreateCategory";
import { Categories } from "./components/core/Dashboard/Admin/Categories";
import { Recruiters } from "./components/core/Dashboard/Admin/Recruiters";
import { Applicants } from "./components/core/Dashboard/Admin/Applicants";
import { CreateAdmin } from "./components/core/Dashboard/Admin/CreateAdmin";
import AdminLogin from "./pages/AdminLogin";
import AdminProfile from "./components/core/Dashboard/Admin/AdminProfile";
import { EditCategory } from "./components/core/Dashboard/Admin/EditCategory";
import ScheduleWalkIn from "./components/core/Dashboard/Recruiter/ScheduleWalkIn";
import { EditWalkInDetails } from "./components/core/Dashboard/Recruiter/EditWalkInDetails";
import MyWalkInDrives from "./components/core/Dashboard/Recruiter/MyWalkInDrives";
import WalkInDrives from "./pages/WalkInDrives";
import WalkInDetails from "./components/core/WalkInDrive/WalkInDetails";
import MyRegistrations from "./components/core/Dashboard/Applicant/MyRegistrations";
import CreateBlog from "./components/core/Dashboard/Recruiter/Add Course";
import MyBlogs from "./components/core/Dashboard/Recruiter/MyBlogs";
import { EditBlog } from "./components/core/Dashboard/Recruiter/EditBlog";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import { apiConnector } from "./services/apiconnector";
import toast from "react-hot-toast";
import { categories } from "./services/apis";
import { Mentorship } from "./pages/MentorShip";
import AddBlog from "./components/core/Dashboard/Recruiter/CreateBlog";
import { ViewRegistrations } from "./components/core/Dashboard/Recruiter/ViewRegistrations";

function App() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    CATEGORIES_API
  } = categories;

  const [confirmationModal,setConfirmationModal] = useState(null);
  const { user } = useSelector(state => state.profile);
  const {token} = useSelector(state => state.auth);
  const {tokenExpiry} = useSelector(state => state.auth);
  const [loading,setLoading] = useState(true);
  const [categoryList,setCategories] = useState([]);

  const showConfirmationModal = ()=>{
    setConfirmationModal({
      text1:"Are you sure?",
      text2:"You will be logged out of your account.",
      btnText1:"Logout",
      btnText2:"Cancel",
      btnHandler1:()=>{
        setConfirmationModal(null);
        dispatch(logout(navigate));
      },
      btnHandler2:()=>{setConfirmationModal(null)}
    });
  }
  
  const deleteAccountModal = ()=>{
    setConfirmationModal({
      text1:"Are you sure?",
      text2:"You account will be deleted permenantly.",
      btnText1:"Delete",
      btnText2:"Cancel",
      btnHandler1:()=>{
        setConfirmationModal(null);
        dispatch(deleteAccount(token,navigate));
      },
      btnHandler2:()=>{setConfirmationModal(null)}
    });
  }

  const fetchSubLinks = async()=>{
    setLoading(true);
    try{
        const result = await apiConnector("GET",CATEGORIES_API);
        setCategories(result?.data.allCategories);
    }catch(err){
        console.log("could not fetch catagories",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    setLoading(false);
  }
  // console.log("sublinks",subLinks);

  useEffect(()=>{
      fetchSubLinks();
  },[]);

  useEffect(()=>{
    if(tokenExpiry && Date.parse(tokenExpiry) < Date.now()){
      dispatch(logout(navigate));
    }
  },[location.pathname]);

  if(loading)
    return <div className="w-screen h-screen bg-richblack-900 flex justify-center items-center profile">
      <Spinner/>
    </div>

  return (
    <div className="w-screen min-h-screen bg-richblack-900 text-white font-inter">
      <Navbar subLinks={categoryList}/>
      
      {
        confirmationModal && <ConfirmationModal modalData={confirmationModal}/>
      }
      
      {
        (
          <Routes>
            <Route path="/" element={<Home setLoading={setLoading} categories={categoryList} />} />
            <Route path="about" element={<About/>}/>
            <Route path="contact" element={<Contact/>}/>
            <Route path="/job/:catalogName" element={<Catalog/>}/>
            <Route path="jobs/:jobRole/:jobId" element={<JobDetail/>} />
            <Route path="/blogs" element={<Blogs/>}/>
            {/* <Route path="/mentorship" element={<Mentorship/>}/> */}
            <Route path="/blogs/:blogTitle/:blogId" element={<BlogDetail/>} />

            <Route
              path="signup"
              element={
                <OpenRoute>
                  <SignUp />
                </OpenRoute>
              }
            />

            <Route
              path="verify-email"
              element={
                <OpenRoute>
                  <VerifyEmail/>
                </OpenRoute>
              }
            />

            <Route
              path="/admin/login"
              element={
                <OpenRoute>
                  <AdminLogin/>
                </OpenRoute>
              }
            />
            
            <Route
              path="login"
              element={
                <OpenRoute>
                  <Login />
                </OpenRoute>
              }
            />

            <Route
              path="forgot-password"
              element={
                <OpenRoute>
                  <ForgotPassword/>
                </OpenRoute>
              }
            />

            <Route
              path="update-password/:accountType/:token"
              element={
                <OpenRoute>
                  <UpdatePassword/>
                </OpenRoute>
              }
            />

            {
              user && user?.accountType === ACCOUNT_TYPE.APPLICANT &&
              <>
                  <Route
                    path="/applicant/apply/:jobId"
                    element={<PrivateRoute>
                      <ApplyJob/>
                    </PrivateRoute>}
                  />
              </>
            }

            {/* WalkInDrive Paths */}
            <Route 
              path="/walk-in-drives/" 
              element={<WalkInDrives/>}>
                <Route path="/walk-in-drives/:walkInType/:walkInId" element={<WalkInDetails/>}/>
            </Route>

            {/* Dashboard Paths */}
            <Route
              element={
                <PrivateRoute>
                  <Dashboard 
                    showConfirmationModal={showConfirmationModal}/>
                </PrivateRoute>
              }>
              
              <Route
                path="/dashboard/settings"
                element={<Settings deleteAccountModal={deleteAccountModal}/>}
              />
              
              <Route
                path="/dashboard/applicant/:id"
                element={<ApplicantProfile/>}
              />
              
              <Route
                path="/dashboard/recruiter/:id"
                element={<RecruiterProfile/>}
              />

              {/* Applicant Routes */}
              {
                user?.accountType === ACCOUNT_TYPE.APPLICANT &&
                <>
                  
                  {/*Recruiter Public Profile Route */}
                  <Route
                    path="/dashboard/recruiter/:recruiterId"
                    element={<RecruiterProfile/>}
                  />

                  {/* Applicant private profile route  */}
                  <Route
                    path="/dashboard/applicant/profile"
                    element={<ApplicantProfile/>}
                  />
                  <Route
                    path="/dashboard/applicant/my-applications"
                    element={<MyApplications/>}
                  />
                  <Route
                    path="/dashboard/applicant/my-registrations"
                    element={<MyRegistrations/>}
                  />
                  {/* Error Paths */}
                  <Route
                    path="*"
                    element={<div className="h-screen w-screen flex items-center justify-center">
                      <p>Error 404 - Page Not Found </p>
                    </div>}
                  />
                </>
              }

              {/* Recruiter Routes */}
              {
                user?.accountType === ACCOUNT_TYPE.RECRUITER &&
                <>      
                  {/* Applicant Public Profile route  */}
                  <Route
                    path="/dashboard/applicant/:applicantId"
                    element={<ApplicantProfile/>}
                  />

                  {/* Recruiter Private Profile Route */}
                  <Route
                    path="/dashboard/recruiter/profile"
                    element={<RecruiterProfile/>}
                  />
                  <Route
                    path="/dashboard/recruiter/applications-recieved"
                  />
                  <Route
                    path="/dashboard/recruiter/my-jobs"
                    element={<MyJobs/>}
                  />
                  <Route
                    path="/dashboard/recruiter/add-job"
                    element={<CreateJob/>}
                  />
                  <Route
                    path="/dashboard/recruiter/edit-job/:jobId"
                    element={<EditJob/>}
                  />
                  <Route
                    path="/dashboard/recruiter/my-walk-in-drives"
                    element={<MyWalkInDrives/>}
                  />
                  <Route
                    path="/dashboard/recruiter/schedule-walk-in"
                    element={<ScheduleWalkIn/>}
                  />
                  <Route
                    path="/dashboard/recruiter/edit-walk-in/:walkInId"
                    element={<EditWalkInDetails/>}
                  />
                  <Route
                    path="/dashboard/recruiter/view-registrations/:walkInId"
                    element={<ViewRegistrations/>}
                  />
                  <Route
                    path="/dashboard/recruiter/applications-received"
                    element={<ReceivedApplications/>}
                  />
                  <Route
                    path="/dashboard/recruiter/application/:applicationId"
                    element={<ApplicationDetail/>}
                  />
                  <Route
                    path="/dashboard/recruiter/create-blog"
                    element={<AddBlog/>}
                  />
                  <Route
                    path="/dashboard/recruiter/edit-blog/:blogId"
                    element={<EditBlog/>}
                  />
                  <Route
                    path="/dashboard/recruiter/my-blogs"
                    element={<MyBlogs/>}
                  />
                </>
              }

              {/* In Future will be admin Routes */}
              {
                user?.accountType === ACCOUNT_TYPE.ADMIN &&
                <>
                  <Route
                  path="/dashboard/admin/profile"
                  element={<AdminProfile/>}
                  />
                  <Route
                    path="/dashboard/admin/create-admin"
                    element={<CreateAdmin/>}
                  />
                  <Route
                    path="/dashboard/admin/create-category"
                    element={<CreateCategory/>}
                  />
                  <Route
                    path="/dashboard/admin/edit-category/:categoryId"
                    element={<EditCategory/>}
                  />
                  <Route
                    path="/dashboard/admin/categories"
                    element={<Categories/>}
                  />
                  <Route
                    path="/dashboard/admin/recruiters"
                    element={<Recruiters/>}
                  />
                  <Route
                    path="/dashboard/admin/applicants"
                    element={<Applicants/>}
                  />
                </>
              }

            </Route>

            {/* Error Paths */}
            <Route
              path="*"
              element={<div className="h-screen w-screen flex items-center justify-center">
                <p>Error 404 - Page Not Found </p>
              </div>}
            />
          
          </Routes>
        )
      }
      
    </div>
  );
}

export default App;
