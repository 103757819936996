import React, { useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchWalkInDriveDetails, registerForWalkInDrive } from '../../../services/operations/JobAPI';
import { formattedFullDate, toDays } from '../../../utils/dateFormatter';
import Spinner from '../../common/Spinner';
import IconBtn from '../../common/IconBtn';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import { FaShareFromSquare } from 'react-icons/fa6';

const WalkInDetails = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {walkInId} = useParams();

  // const walkInId = location.state.walkInId;
  const {token} = useSelector(state => state.auth);
  const {user} = useSelector(state => state.profile);

  const [wd, setWd] = useState({});
  const [loading,setLoading] = useState(false);
  const [confirmationModal,setConfirmationModal] = useState(null);

  const setWalkInData = async(walkInId)=>{
      setLoading(true);
      const result = await fetchWalkInDriveDetails(walkInId);
      if(result){
          setWd(result);
      } 
      setLoading(false);
  }
  // console.log("result: ",course);

  useLayoutEffect(()=>{

      setWalkInData(walkInId);

  },[walkInId]);
  // console.log(wd)

  
  const viewRecruiter = (id)=>{

    //not logged in user
    if(!token){
        localStorage.setItem("path",location.pathname);
        setConfirmationModal({
            text1:"You are not logged in!",
            text2:"Please login to View.",
            btnText1:"Login",
            btnText2:"Cancel",
            btnHandler1:()=>{navigate('/login')},
            btnHandler2:()=>{setConfirmationModal(null)}
        });
        return;
    }
    
    //If Other Recruiter 
    if(user?.accountType === ACCOUNT_TYPE.RECRUITER){
        toast.error("User must not be a Recruiter");
        return;
    }
    
    navigate(`/dashboard/recruiter/${id}`);
  }

  const handleShare = ()=>{
    //getting current url from window class and location object and 
    //sending it to copy function of react copy to clipboard function 
    copy(window.location.href);
    toast.success("Link Copied");
  }

  
  const isTrue = ()=>{
    // console.log(wd?.registrations)
    return wd?.registrations?.some(rt => rt?._id === user?._id);
  }
  
  //submit handler for buy button in form
  const handleWalkInRegistration = async(e)=>{
    e.preventDefault();
    
    setLoading(true);

    //not logged in user
    if(!token){
      localStorage.setItem("path",location.pathname);
      setConfirmationModal({
            text1:"You are not logged in!",
            text2:"Please login to Register.",
            btnText1:"Login",
            btnText2:"Cancel",
            btnHandler1:()=>{navigate('/login')},
            btnHandler2:()=>{setConfirmationModal(null)}
        });
        setLoading(false);
        return;
    }

    //Not an Applicant 
    if(user?.accountType !== ACCOUNT_TYPE.APPLICANT){
        setLoading(false);
        toast.error("User must be an Applicant");
        return;
    }

    //if already registered
    if(isTrue()){
        navigate('/dashboard/applicant/my-registrations');
        return;
    }

    if(!user?.firstName || !user?.lastName || !user?.mobile || !user?.description){
        setLoading(false);
        toast.error("User must update profile");
        return;
    }

    //register of walk in drive 
    const formData = new FormData();
    formData.append("walkInId",wd?._id);
    formData.append("companyId",wd?.company?._id);

    const result = await registerForWalkInDrive(formData,token,navigate);
    // console.log("result: ",result);

    setLoading(false);
  }


  if(loading)
    return <Spinner/>

  if(loading && !wd)
    return <Spinner/>

  // console.log(wd)
  return (
    wd &&
    <div className='w-full h-full'>
      <div className='text-2xl font-bold py-3 w-full border-b mb-4 flex flex-wrap justify-between'>
        <span>Check details</span>

        {/* Button Container */}
        <div className='flex gap-2'>
          {/* Share Button */}
          <button
            className='font-medium text-lg rounded-md text-yellow-50 flex gap-x-2 items-center justify-center'
            type='button'
            onClick={handleShare}
          >
            <FaShareFromSquare/>
          </button>
          {/* Recruiter Profile Button */}
          <IconBtn
            type={'button'}
            onclick={()=>viewRecruiter(wd?.company?._id)}
            text={"View Recruiter"}
            customClasses={'text-sm'}
          />
        </div>
      </div>
      <p 
        className='text-xl font-bold flex items-center gap-3 flex-wrap text-yellow-50'
      >
        Roles - 
        {
          wd && wd?.roles?.map((role,id)=>(
            <span key={id}>{role}</span>
          ))
        }
      </p>
      <p>
        <span className='font-bold'>Category - </span>
        <span>{wd?.category?.name}</span>
      </p>
      <p>
        <span className='font-bold'>Location - </span>
        <span>{wd?.location}</span>
      </p>
      <p>
        <span className='font-bold'>Scheduled On - </span>
        <span>{formattedFullDate(wd?.scheduleDay)}</span>
      </p>
      <p>
        <span className='font-bold'>Last Date to Register - </span>
        <span>{formattedFullDate(wd?.deadline)}</span>
      </p>
      <p className='text-sm text-yellow-50'>
        Posted on {formattedFullDate(wd?.createdAt)}
      </p>

      <div className='flex flex-wrap py-4 gap-4 border-b mb-8 mt-2'>
        {
          wd?.details &&
          <a href={wd?.details} target='_blank' className='flex-grow'>
            <IconBtn outline={true} customClasses={"w-full"}>
              View Drive Details
            </IconBtn>
          </a>
        }
        <IconBtn
          onclick={e => handleWalkInRegistration(e)}
          disabled={isTrue()}
          customClasses={"flex-grow"}
        >
          {isTrue() ? "Registered" :"Register Now"}
        </IconBtn>
      </div>
      {   
        confirmationModal &&
        <ConfirmationModal
            modalData={confirmationModal}
        />
      }
    </div>
  )
}

export default WalkInDetails