import React from 'react';
import signupImg from "../assets/Images/frame.png";
import { Template } from '../components/core/Auth/Template';

export const SignUp = () => {
  return (
    <div className='w-11/12 max-w-maxContent mx-auto flex justify-center items-center'>
          <Template
            title="Join CareerCloudHub today and take the next step in your professional journey!"
            desc1="Leverage AI-driven recruitment solutions"
            desc2="Your Gateway to Global Opportunities"
            frame={signupImg}
            formtype="signup"
          />
    </div>
  )
}
