import React, { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { NavbarLinks } from '../../data/navbar-links';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { ProfileDropdown } from '../core/Auth/ProfileDropdown';
import { ACCOUNT_TYPE } from '../../utils/constants';
import { GiHamburgerMenu } from "react-icons/gi";
import { logout } from '../../services/operations/authAPI';
import { IoMdClose } from 'react-icons/io';
import logo from '../../assets/Logo/logo.png';

export const Navbar = ({subLinks}) => {
    
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {token} = useSelector(state => state.auth);
    const { user } = useSelector(state => state.profile);
    const { totalItems } = useSelector(state => state.cart);
    const [active,setActive] = useState(false);

    // console.log("sublinks",subLinks);

    const parseCategoryName = (name)=>{
        name = name.split(" ").join("-").toLowerCase();
        return name;
    }

  return (
    <div className={` ${token && location.pathname.includes('dashboard') && "fixed bg-richblack-800 z-20 top-0 left-0"} w-full border-richblack-700 border-b`}>
        <div className='w-11/12 max-w-maxContent mx-auto flex flex-1 justify-between lg:justify-between items-center lg:p-[.4rem]  py-[0.5rem] relative'>
            
            {/* logo */}
            <Link to={'/'} className='max-lg:flex justify-center items-center gap-1 p-1'>
                <div className='w-[60px] h-[40px]'>
                    <img src={logo} className='w-full h-full object-contain'/>
                </div>
                {/* <span className='text-xl font-bold'>CareerCloudHub</span> */}
            </Link>

            <div className={`${!active && "max-lg:hidden"} flex flex-col justify-between items-center max-lg:absolute right-0 top-2 z-[150] max-md:w-[90vw] max-md:h-[100vh] max-lg:bg-richblack-800 gap-2 max-lg:p-6 py-3 max-lg:rounded-sm max-lg:overflow-x-hidden max-lg:overflow-y-scroll`}>
                {
                    <div className='lg:hidden cursor-pointer self-end bg-white text-black p-1 rounded-full' onClick={()=>setActive(prev => !prev)}>
                        <IoMdClose className='text-xl font-extrabold'/>
                    </div>
                }
                {/* nav-links */}
                <nav>
                    <ul className='flex max-lg:flex-col items-center justify-between gap-2 text-[17px] text-richblack-25 cursor-pointer'>
                        {
                            NavbarLinks?.map((link, index)=>{
                                return <li 
                                    key={`${link.title}-${index}`} 
                                    className='p-2 whitespace-nowrap max-lg:w-full max-lg:text-center'
                                    onClick={()=>setActive(false)}
                                    >
                                    {
                                        link.title === 'Jobs'
                                        ? (<div className='relative flex flex-col lg:flex-row items-center group'>
                                            <div className='flex gap-x-1 items-center'>
                                                <span>{link.title}</span>
                                                <MdKeyboardArrowDown className='text-[26px]'/>
                                            </div>
                                            
                                            {/* Visible for viewport less than 'md' */}
                                            <div className='lg:hidden flex flex-col items-center gap-y-2 px-2 w-full'>
                                                {
                                                    subLinks?.map((category)=>
                                                        <Link to={`/job/${parseCategoryName(category?.name)}`} key={category?._id}>
                                                            <p className='text-[14px] text-richblack-200 hover:text-richblack-50 hover:bg-opacity-95 rounded-lg w-[100%] px-2 py-1'>{category?.name}</p>
                                                        </Link>
                                                    )
                                                }
                                            </div>

                                            {/* Visible for viewport greater than 'md' */}
                                            <div className={`max-lg:hidden invisible opacity-0 lg:min-w-[550px] absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[15%] grid ${
                                                subLinks && subLinks?.length > 10 ? "grid-rows-7" : "grid-rows-5"
                                            } grid-flow-col p-4  bg-richblack-5 group-hover:visible group-hover:opacity-100 group-hover:translate-y-[10%] rounded-lg transition-all ease-in-out duration-200 z-50 text-richblack-800`}>
                                                <div className='absolute w-6 h-6 top-0 translate-y-[-45%] left-[50%] rounded
                                                rotate-45 bg-richblack-5'>
                                                </div>
                                                {
                                                    subLinks?.map((category)=>
                                                        <Link to={`/job/${parseCategoryName(category?.name)}`} key={category?._id}>
                                                            <p className='hover:bg-richblack-50 hover:bg-opacity-95 p-4 rounded-lg'>{category?.name}</p>
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        </div>)
                                        :(
                                            <NavLink to={link.path}>
                                                <p className='nav-link'>
                                                    {link.title}
                                                </p>
                                            </NavLink>
                                        )
                                    }
                                    <hr className='lg:hidden text-richblack-700' />
                                </li>
                            })
                        }
                    </ul>
                </nav>

                {/* Login Signup Dashboard */}
                {
                    !token &&
                    <Link className='lg:hidden w-full text-center px-4' to={'/login'} onClick={()=>setActive(false)}>
                        <button className='px-3 py-2 bg-richblack-800'>
                            Log in
                        </button>
                        <hr className='lg:hidden text-richblack-700' />
                    </Link>
                }
                {
                    !token &&
                    <Link className='lg:hidden w-full text-center px-4' to={'/signup'} onClick={()=>setActive(false)}>
                        <button className='px-3 py-2 bg-richblack-800'>
                            Sign up
                        </button>
                    </Link>
                }

                {
                    token &&
                    <div className='lg:hidden flex flex-col gap-y-2'>
                        <Link to={`/dashboard/${
                                user?.accountType === ACCOUNT_TYPE.APPLICANT ? 'applicant' 
                                :(user?.accountType === ACCOUNT_TYPE.RECRUITER ? 'recruiter' : 'admin')
                            }/profile`} onClick={()=>setActive(false)}>
                            <p className='p-4 py-2'>
                                <span>Dashboard</span>
                            </p>
                        </Link>
                        <button onClick={()=>{
                            dispatch(logout(navigate))
                            setActive(false);
                        }}>
                            <p className='p-4 py-2 '>
                                <span>Log Out</span>
                            </p>
                        </button>
                    </div>
                }

            </div>
            
            {/* For viewport greater than md Login Signup Dashboard */}
            <div className={`max-lg:hidden flex items-center gap-2 ${token && "hidden"} bg-richblack-800 rounded-full p-1 border-[.1px] border-richblack-5 font-bold text-richblack-5`}>
                
                {
                    !token &&
                    <Link to={'/login'}>
                        <button className='p-2'>
                            Log in
                        </button>
                    </Link>
                }
                {
                    !token &&
                    <Link to={'/signup'}>
                        <button className='px-3 p-2 bg-richblack-5 text-richblack-900 border border-richblack-700 rounded-full'>
                            Sign up
                        </button>
                    </Link>
                }
            </div>

            {/* Cart and Profile Dropdown */}
            <div className={`flex items-center gap-x-2 ${!token && "hidden"}`}>
                    {/* {
                        user && user.accountType !== ACCOUNT_TYPE.INSTRUCTOR &&
                        <Link to={'/dashboard/cart'}>
                            <div className='relative '>
                                <MdOutlineShoppingCart className='text-2xl text-richblack-25'/>
                                {
                                    totalItems > 0 &&
                                    <span className='absolute -bottom-[40%] -right-[40%] p-[1px] px-[7px] text-sm text-yellow-50 font-bold rounded-full bg-richblack-600'>
                                        {totalItems}
                                    </span>
                                }
                            </div>
                        </Link>
                    } */}
                    {
                        token &&
                        <Link to={`/dashboard/${
                            user?.accountType === ACCOUNT_TYPE.APPLICANT ? 'applicant' 
                            :(user?.accountType === ACCOUNT_TYPE.RECRUITER ? 'recruiter' : 'admin')
                        }/profile`} onClick={()=>setActive(false)}>
                            <button className='p-2 bg-richblack-700 border-richblack-100 border-[.1px] rounded-md lg:hidden'>
                                Dashboard
                            </button>
                        </Link>
                    }
                    {
                        token &&
                        <div onClick={()=>setActive(prev => !prev)}><ProfileDropdown/></div>
                    }
            </div>
            {/* Menu button */}
            {
                !token &&
                <div className='lg:hidden cursor-pointer text-xl font-extrabold p-2 bg-richblack-5 text-black rounded-full self-end' onClick={()=>setActive(prev => !prev)}>
                    <GiHamburgerMenu />
                </div>
            }
        </div>
    </div>
  )
}
