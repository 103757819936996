import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchRecruiterPublicProfile } from '../../../../services/operations/SettingsAPI';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../common/Spinner';
import { JobSlider } from '../../Catalog/Job_Slider';
import { ACCOUNT_TYPE } from '../../../../utils/constants';
import IconBtn from '../../../common/IconBtn';


const RecruiterProfile = () => {

  const navigate = useNavigate(); 
  const {id} = useParams();
  const {token} = useSelector(state => state.auth);
  const { user } = useSelector(state => state.profile);
  const [recruiter, setRecruiter] = useState({});

  useEffect(()=>{

    if(!id)
      setRecruiter(user);
    else 
    setRecruiterDetails(id);

  },[id]);    

  async function setRecruiterDetails(id){
    const result = await fetchRecruiterPublicProfile(token,id); // fetch result
    if(result){
      setRecruiter(result);
    }
  }

  const handleEditProfile = ()=>{
    navigate("/dashboard/settings");
  }

  // console.log(applicant)
  if(!recruiter)
      return <Spinner/>
  return (
      <div className='flex flex-col gap-10 w-11/12 text-richblack-5 max-w-[1000px] mx-auto profile'>
        <p className='text-3xl tracking-wide mb-4 font-medium'>My Profile</p>
        
        {/* Section 1 - Company Personal Details */}
        {
          (user?._id === recruiter?._id || user?.accountType === ACCOUNT_TYPE.ADMIN) &&
          <section className='flex flex-col justify-between gap-10 bg-richblack-800 p-8 sm:px-12 px-6 rounded-md border border-richblack-700'>
    
            {/* Heading and Edit BTN */}
            <div className='flex justify-between items-center'>
              <p className='text-lg font-bold'>Company Details</p>
              <IconBtn
                text={"Edit"}
                type={"button"}
                onclick={()=>{handleEditProfile()}}
              />
            </div>
            
            {/* Details */}
            <div className='flex flex-col gap-5 max-w-[500px] text-[15px]'>

              <div className=' w-full flex max-xxs:flex-col gap-4 xxs:items-center justify-between'>
                
                <label className='flex flex-col gap-2 xxxs:w-[30%]'>
                  <p className='text-sm text-richblack-500'>Company Name</p>
                  <p>{recruiter?.companyName}</p>
                </label>
                
                <label className='flex flex-col gap-2 xxxs:w-[30%]'>
                  <p className='text-sm text-richblack-500'>Company GST No</p>
                  <p>{recruiter?.gstNo}</p>
                </label>
              </div>
            
              <div className=' w-full flex max-xmd:flex-col gap-4 xmd:items-center justify-between'>
                <label className='flex flex-col gap-2 xmd:w-[30%]'>
                  <p className='text-sm text-richblack-500'>Company Email</p>
                  <p>{recruiter?.email}</p>
                </label>
                
                <label className='flex flex-col gap-2 xmd:w-[30%]'>
                  <p className='text-sm text-richblack-500'>Company Contact</p>
                  <p>
                    {
                      recruiter && recruiter?.mobile
                      ? recruiter?.mobile
                      : "Add Contact Number" 
                    }
                  </p>
                </label>
              </div>
              
              <div className='w-full flex max-xmd:flex-col gap-4 xmd:items-center justify-between'>
                <label className='flex flex-col gap-2 xmd:w-[30%]'>
                  <p className='text-sm text-richblack-500'>POC Designation</p>
                  <p>
                    {
                      recruiter && recruiter?.pocDesignation 
                      ? recruiter?.pocDesignation
                      : "Add Designation"
                    }
                  </p>
                </label>
                
                <label className='flex flex-col gap-2 xmd:w-[30%]'>
                  <p className='text-sm text-richblack-500'>POC</p>
                  <p>
                    {
                      recruiter && recruiter?.poc
                      ? recruiter?.poc
                      : "Add POC" 
                    }
                  </p>
                </label>
              </div>

              <div className='w-full flex max-xmd:flex-col gap-4 xmd:items-center justify-between'>
                <label className='flex flex-col gap-2 w-full'>
                  <p className='text-sm text-richblack-500'>Address</p>
                  <p>
                    {
                      recruiter && recruiter?.address 
                      ? recruiter?.address
                      : "Add Address"
                    }
                  </p>
                </label>
              </div>
            </div>
  
          </section>
        }
        
        {/* Section 2 - Description*/}
        <section className='flex flex-col justify-between gap-10 bg-richblack-800 p-8 sm:px-12 px-6 rounded-md border border-richblack-700'>
            <div className='flex justify-between items-center'>
              <p className='text-lg font-bold'>Description</p>
            </div>
            <p className='w-[90%]'>
              {
                recruiter?.description != null 
                ? <span>{recruiter?.description}</span>
                : <span className='text-richblack-300 text-[14px] tracking-wide'>
                  Write Something About Yourself
                </span>
              }
            </p>
        </section>

         {/* Section 3 - Jobs Sections */}
         <section className='flex flex-col justify-between gap-10 bg-richblack-800 p-8 sm:px-12 px-6 rounded-md border border-richblack-700'>
          <div className='flex justify-between items-center'>
            <p className='text-lg font-bold'>Jobs Posted</p>
          </div>
          <div className='w-full'>
            <JobSlider jobs={recruiter?.jobs} slides={2} />
          </div>
        </section>
      </div>
  )
}

export default RecruiterProfile;