import React from 'react'
import { Job_card } from './Job_card';
// Import Swiper React components
import { Swiper , SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// Import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

export const JobSlider = ({jobs,height,slides}) => {
  return (
    <div className='my-4'>{
      jobs?.length &&
      <Swiper
        slidesPerView={1}
          spaceBetween={20}
          modules={[Autoplay,FreeMode,Pagination]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            980: {
              slidesPerView: 3 || slides,
            },
          }}
          className="grid sm:grid-cols-3 p-2 lg:gap-4"
      >
        {
          jobs.map((job)=>(
            <SwiperSlide key={job?._id} className='hover:scale-105 transition-all duration-200'>
                <Job_card job={job} Height={height} 
                  path={
                    `/jobs/${job?.role.toLowerCase().split(" ").join("-")
                      .replace(/[^a-zA-Z0-9 -]/g,"")
                      .replace(/-{2,}/g, "-")
                      .replace(/^-|-$/g, "")
                    }/${job?._id}`
                }/>
            </SwiperSlide>
          ))  
        }
      </Swiper> 
    }</div>
  )
}
