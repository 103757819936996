import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../../../common/Spinner';
import { fetchWalkInDriveDetails } from '../../../../services/operations/JobAPI';

export const ViewRegistrations = () => {

    const {walkInId} = useParams();
    const [loading,setLoading] = useState(false);
    const [walkInDetails, setWalkInDetails] = useState([]);
    
    const setWalkInData = async(walkInId)=>{
        setLoading(true);
        const result = await fetchWalkInDriveDetails(walkInId);
        setWalkInDetails(result);
        setLoading(false);
    }

    useEffect(()=>{

        setWalkInData(walkInId);

    },[]);

    if(loading)
        return <Spinner/>

  return (
    <div className='w-11/12 mx-auto text-richblack-5 font-inter flex flex-col justify-center py-10'>
        <p className='text-3xl font-bold'>Walk In Registrations</p>
        {
            !loading && walkInDetails && walkInDetails?.registrations?.length 
            ? (
                <div className='flex items-center flex-wrap gap-4 py-8 px-3'>
                    {
                        walkInDetails?.registrations?.map((rg,id)=>(
                            <p className='px-3 py-2 rounded-md bg-yellow-25 bg-opacity-80 font-bold text-richblack-900 text-sm hover:opacity-80 duration-100'>
                                <Link to={`/dashboard/applicant/${rg?._id}`}>View {rg?.firstName}'s Profile</Link>
                            </p>
                        ))
                    }
                </div>
            )
            : <p className='text-center text-yellow-25 py-10 text-2xl font-bold'>!! No Registrations Yet</p>
        }
    </div>
  )
}
