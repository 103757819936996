import React from 'react'
import Counter from '../../common/Counter'

const data = [
    {
        count:"60",
        label:"Employers",
        duration: 4
    },
    {
        count:"400",
        label:"Jobs",
        duration: 5
    },
    {
        count:"5000",
        label:"Active Job Searches",
        duration: 7
    }
]

export const StatsComponent = () => {
  return (
    <div className='mx-auto w-11/12 max-w-maxContent flex items-center justify-around py-5 flex-wrap'>
        {
            data.map( (stats,index) => 
                <Counter key={index} data={{
                    number:stats.count,
                    label:stats.label,
                    duration:stats.duration
                }} />
            )
        }
    </div>
  )
}
