import React from 'react'
import { Link } from 'react-router-dom'

export const BlogCard = ({bg}) => {
  return (
    <Link to={`/blogs/${bg?.title?.toLowerCase().split(" ").join("-")
      .replace(/[^a-zA-Z0-9 -]/g,"")
      .replace(/-{2,}/g, "-")
      .replace(/^-|-$/g, "")}/${bg?._id}`}
      // state={{blogId:bg?._id}}  
    >
      <div className='flex flex-col gap-y-2 justify-center p-4 border border-richblack-100 rounded-md min-w-[250px] sm:max-w-[300px] w-full hover:border-yellow-100 hover:-translate-y-2 transition-all duration-200 max-h-[350px]'>
        <div className='rounded-md overflow-hidden w-full max-h-[200px]'>
          <img src={bg?.featuredImage} className='w-full object-contain  rounded-md'/>
        </div>
        <p className='text-lg font-bold'>{bg?.title?.slice(0,25)}...</p>
        <p className='text-justify text-sm text-richblack-50'>
          <span>{bg?.subtitle?.slice(0,200)}...  </span>
          <span className='font-bold text-yellow-100'>Read More</span>
        </p>
      </div>
    </Link>
  )
}
