import React from 'react'
import { FooterLink1 } from '../../data/footer-links';
import { FooterLink2 } from '../../data/footer-links'
import * as Icons from 'react-icons/io5';
import logo from '../../assets/Logo/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {

    const categories = [
        {
            name: "Full Stack Development",
            // img: <FaLaptopCode className='bg-[#E6FFF5] p-3 rounded-full' />,
        },
        {
            name: "Cyber Security",
            // img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
        },
        {
            name: "ERP Developer",
            // img: <RiHeadphoneFill className='bg-[#DDEDFF] p-3 rounded-full' />,
        },
        {
            name: "SAAS Developer",
            // img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
        },

    ]

    const categories2 = [
      {
          name: "Frontend Development",
          // img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
      },
      {
          name: "Backend Development",
          // img: <IoBagHandle className='bg-[#FFE9E7] p-3 rounded-full' />,
      },
      {
          name: "Sales and Marketing",
          // img: <IoMdSettings className='bg-[#FFEEE0] p-3 rounded-full' />,
      },
      {
          name: "ERP Developer",
          // img: <RiHeadphoneFill className='bg-[#DDEDFF] p-3 rounded-full' />,
      },
      {
          name: "SAAS Developer",
          // img: <HiOutlinePresentationChartLine className='bg-[#EBE8FD] p-3 rounded-full'/>,
      },
    ]

  const getIcon = (iconName)=>{
    const Icon = Icons[iconName];
    return <Icon className='text-richblack-400 text-xl'/>
  }

  return (
    <footer className="footer2-outer-container">
        <div className="footer2-inner-container">
            <div className="footer2-logo-section flex items-center gap-x-2 h-fit">
              <div className='w-[60px] h-[40px]'>
                <img src={logo} className='w-full h-full object-contain'/>
              </div>
              <p className='text-2xl font-bold'>CareerCloudHub</p>
            </div>
            <div className="footer2-inners">
                <div className="footer2-blogs">
                  {
                    categories && categories?.length &&
                    categories?.map((ct,id) => (
                      <div key={id} className="footer2-blog-card animated-border">
                        <a href={`https://www.careercloudhub.com/job/${
                          ct?.name?.toLowerCase().split(" ").join("-")
                          .replace(/[^a-zA-Z0-9 -]/g,"")
                          .replace(/-{2,}/g, "-")
                          .replace(/^-|-$/g, "")
                        }`}>
                            <p className="footer2-heading">{ct?.name}</p>
                            <p>Explore Jobs</p>
                        </a>
                      </div>
                    ))
                  }
                </div>
                {/* <!-- <a href="#"> --> */}
                <div className="footer2-section">

                    {/* Quick Links */}
                    <div>
                      <h3 className="footer2-heading">Quick Links</h3>
                      <a href="https://www.careercloudhub.com/" className="footer-link">
                          <li>Home</li>
                      </a>
                      <a href="https://www.careercloudhub.com/about" className="footer-link">
                          <li>About</li>
                      </a>
                      <a href="https://www.careercloudhub.com/walk-in-drives" className="footer-link">
                          <li>Walk In Drives</li>
                      </a>
                      <a href="https://www.careercloudhub.com/blogs" className="footer-link">
                          <li>Blogs</li>
                      </a>
                      <a href="https://www.careercloudhub.com/contact" className="footer-link">
                          <li>Contact Us</li>
                      </a>
                    </div>

                    {/* Jobs */}
                    <div className='hidden lg:block'>
                      <h3 className="footer2-heading">Job Cetgories</h3>
                      {
                        categories2 && categories2?.length &&
                        categories?.map((ct,id) => (
                        <a key={id} href={`https://www.careercloudhub.com/job/${
                          ct?.name?.toLowerCase().split(" ").join("-")
                          .replace(/[^a-zA-Z0-9 -]/g,"")
                          .replace(/-{2,}/g, "-")
                          .replace(/^-|-$/g, "")
                        }`} className="footer-link">
                          <li>{ct?.name}</li>
                        </a>
                        ))
                      }
                    </div>
                </div>
                {/* <!-- </a> --> */}
            </div>
        </div>
        {/* Copyright  */}
    <div className='w-full flex items-center justify-center max-md:flex-col gap-y-2 py-6 pb-4 border-t border-richblack-700'>
            
            <p className='text-[15px] text-richblack-400'>
                © 2024 Created By <a href="https://matchbestsoftware.com/" target='_blank' className='font-bold text-richblack-100'>
                 MatchBest Software Pvt.Ltd
               </a>
            </p>
          </div>
    </footer>
  )
}

// <footer className="footer2-outer-container">
//         <div className="footer2-inner-container">
//             <div className="footer2-logo-section">
//                 <a href="/">
//                     <img src="/assets/images/Maverick.webp" alt="Maverick Elite Tech Logo" className="footer2-logo"/>
//                 </a>
//             </div>
//             <div className="footer2-inners">
//                 <div className="footer2-blogs">
//                     <div className="footer2-blog-card animated-border">
//                         <a href="https://ai.maverickelitetech.com/">
//                             <h3 className="footer2-heading">AI Images Generator</h3>
//                             <p>Generate stunning images from text instant with AI.</p>
//                         </a>
//                     </div>
//                     <div className="footer2-blog-card">
//                         <a href="/tools/fitness-calculators.html">
//                             <h3 className="footer2-heading">Fitness Calculators</h3>
//                             <p>Track your BMI, calories, and fitness goals easily.</p>
//                         </a>
//                     </div>
//                     <div className="footer2-blog-card">
//                         <a href="/tools/financial-calculators.html">
//                             <h3 className="footer2-heading">Financial Calculators</h3>
//                             <p>Plan loans, investments, and savings smartly.</p>
//                         </a>
//                     </div>
//                     <div className="footer2-blog-card">
//                         <a href="/blogs/blog1.html">
//                             <h3 className="footer2-heading">Blogs</h3>
//                             <p>Read expert insights on AI, tech, and business.</p>
//                         </a>
//                     </div>
//                 </div>
//                 {/* <!-- <a href="#"> --> */}
//                 <div className="footer2-section">
//                     <h3 className="footer2-heading">Advanced AI Tools</h3>
//                     <a href="https://ai.maverickelitetech.com/" className="footer-link">
//                         <li>AI Images Generator</li>
//                     </a>
//                     <a href="https://ai.maverickelitetech.com/" className="footer-link">
//                         <li>Text-to-Voice Generator</li>
//                     </a>
//                     <a href="https://ai.maverickelitetech.com/" className="footer-link">
//                         <li>Image Size Compressor</li>
//                     </a>
//                     <a href="https://ai.maverickelitetech.com/" className="footer-link">
//                         <li>Youtube Videos Generator</li>
//                     </a>
//                     <a href="https://ai.maverickelitetech.com/" className="footer-link">
//                         <li>Instagram Hashtags Generator</li>
//                     </a>
//                     <a href="https://ai.maverickelitetech.com/" className="footer-link">
//                         <li>AI Blogs Generator</li>
//                     </a>
//                 </div>
//                 {/* <!-- </a> --> */}
//             </div>
//         </div>
//     </footer>

export default Footer