import { Link, useLocation } from 'react-router-dom'
import {formattedDate} from '../../../utils/dateFormatter'
import { deleteJobByRecruiter } from '../../../services/operations/JobAPI';
import { useDispatch, useSelector } from 'react-redux';
import { FaUserGroup } from 'react-icons/fa6';
import { BsFillDiagram3Fill } from 'react-icons/bs';
import { setUser } from '../../../slices/profileSlice';

export const Job_card = ({job,deleteJob,path}) => {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const {token} = useSelector(state => state.auth);

  const deleteJobById = async(jobId)=>{
    const result = await deleteJobByRecruiter({jobId},token);
    if(result){
      // console.log(result);
      dispatch(setUser(result));
    }
    deleteJob(jobId);
  }
// console.log(job)
  return (
      // path ? (
      //   <Link to={path}>
      //      <div className="card example-3">
      //         <div className='cursor-pointer inner bg-richblue-900'>
      //           <div className='flex flex-col gap-y-2'>
      //             <p className='text-xl font-semibold'>{job && job?.role}</p>
      //             <p className='text-richblack-200'>
      //               {job && `${job?.company?.maskCompanyName ? "" :job?.company?.companyName}`}
      //             </p>
      //             <div className='flex gap-2 items-center tracking-wide'>
      //               <p className='text-richblack-25'>{`${job?.workPreference?.workStatus}`}</p>
      //               <p className='text-richblack-25'>{`${job?.workPreference?.employmentType}`}</p>
      //             </div>
      //             <div className='flex items-center gap-2 flex-wrap'>
      //               {
      //                 job?.skills?.slice(0,4).map((sk,index) => (
      //                   <span className='text-yellow-25' key={index}>{sk}</span>
      //                 ))
      //               }
      //             </div>
      //             <p className='text-sm text-richblack-50'>Posted on {formattedDate(job?.createdAt)}</p>
      //             <div className='flex items-baseline gap-x-3 flex-wrap pr-4 mt-2'>
      //               <p className={` px-3 py-[0.1rem] rounded-xl bg-opacity-40 text-sm
      //                   ${job?.active ? "bg-caribbeangreen-400 text-caribbeangreen-200"
      //                                 :"bg-pink-500 text-pink-300"}
      //                 `}>
      //                 {`${job?.active ? "Active" : "Expired"}`}
      //               </p>
      //               {
      //                 location.pathname === '/dashboard/recruiter/my-jobs' && 
      //                 <div className='flex gap-x-3 items-center'>
      //                   <Link to={`/dashboard/recruiter/edit-job/${job?._id}`}>
      //                     <button className='text-sm text-richblack-200 hover:text-caribbeangreen-200 duration-100 p-1'>
      //                       Edit
      //                     </button>
      //                   </Link>
      //                   <button
      //                     onClick={e => {
      //                       e.preventDefault();
      //                       deleteJobById(job?._id)
      //                     }}
      //                   className='text-sm text-richblack-200 hover:text-pink-300 duration-100 p-1'>
      //                     Delete
      //                   </button>
      //                 </div>
      //               }
      //             </div>
      //           </div>
      //         </div>
      //       </div>
          
      //   </Link>
      // )
      // :(
      //   <div className='flex flex-col gap-y-5 select-none border p-3 rounded-md h-full'>
      //     <div className='flex flex-col gap-y-2'>
      //       <p className='text-lg'>{job && job?.role}</p>
      //       {/* <p className='text-richblack-200'>
      //         {job && `${job?.company?.companyName}`}
      //       </p> */}
      //       <div className='flex gap-3 items-center tracking-wide'>
      //         <p className='text-richblack-25'>{`Open for ${job?.workPreference?.workStatus}`}</p>
      //         <p className='text-richblack-25'>{`${job?.workPreference?.employmentType}`}</p>
      //       </div>
      //       <div className='flex items-center gap-2 flex-wrap'>
      //         {
      //           job?.skills?.slice(0,3).map((sk,index) => (
      //             <span className='text-yellow-25' key={index}>{sk}</span>
      //           ))
      //         }
      //       </div>
      //       <p className='text-sm text-richblack-50'>Posted on {formattedDate(job?.createdAt)}</p>
      //       <div className='flex items-baseline gap-x-3 flex-wrap pr-4 mt-2'>
      //         <p className={` px-3 py-[0.1rem] rounded-xl bg-opacity-40 text-sm
      //             ${job?.active ? "bg-caribbeangreen-400 text-caribbeangreen-200"
      //                           :"bg-pink-500 text-pink-300"}
      //           `}>
      //           {`${job?.active ? "Active" : "Expired"}`}
      //         </p>
      //         {
      //           location.pathname === '/dashboard/recruiter/my-jobs' && 
      //           <div className='flex gap-x-3 items-center'>
      //             <Link to={`/dashboard/recruiter/edit-job/${job?._id}`}>
      //               <button className='text-sm text-richblack-200 hover:text-caribbeangreen-200 duration-100 p-1'>
      //                 Edit
      //               </button>
      //             </Link>
      //             <button
      //               onClick={e => {
      //                 e.preventDefault();
      //                 deleteJobById(job?._id);
      //               }}
      //               className='text-sm text-richblack-200 hover:text-pink-300 duration-100 p-1'>
      //               Delete
      //             </button>
      //           </div>
      //         }
      //       </div>
      //     </div>
      // </div>
      // )
      <Link to={path}>
        <div className={`w-full h-full flex flex-col justify-between select-card rounded-md overflow-hidden`}>
          <div className='flex flex-col items-start justify-around py-4 px-4 text-black w-full h-full'>
            <p className={`font-bold text-xl select-heading`}>{job?.role}</p>
            <div className=''>
              {
                job && !job?.company?.maskCompanyName &&
                  <p className={`text-sm select-subheading`}>{job.company?.companyName}</p>
              }         
              {/* Skills */}
              <div className='w-full flex flex-col justify-start px-1 text-[#0A5A72]'>
                <label className='font-bold'>Skills:</label>
                <ul className='list-disc px-5 text-sm grid sm:grid-cols-2 gap-x-4 py-1'>
                  {
                    job?.skills?.slice(0,6)?.map((sk,id) => (
                      <li key={id}>{sk}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
   

            <div className='w-full flex flex-col items-start py-2 px-1 text-[#0A5A72]'>
                <div>
                  <span className='font-bold text-md '>Location: </span>
                  {
                    // console.log(job) &&
                    job?.workPreference?.location?.map((ln,id) => (
                      <span key={id} className='text-sm'>{ln} </span>
                    ))
                  }
                </div>
                <div className='flex gap-2 justify-center items-center text-sm font-bold'>
                  <p className='text-yellow-500'>500+ Applied</p>
                  <p className={` px-3 py-[0.1rem] rounded-xl bg-opacity-40
                    ${job?.active ? " text-caribbeangreen-200"
                                    :" text-pink-300"}
                    `}>
                    {`${job?.active ? "Active" : "Expired"}`}
                  </p>
                </div>
            </div>
          </div>
          
          <div className={`flex justify-between items-center p-4 py-3 border-t teext-sm  border-dashed font-medium select-tag`}>
            <div className='flex gap-1 items-center w-fit'>
              <FaUserGroup/>
              <p>{job?.workPreference?.workStatus}</p>
            </div>
            <div className='flex gap-1 items-center w-fit whitespace-nowrap'>
            {
              location.pathname === '/dashboard/recruiter/my-jobs' && 
              <div className='flex gap-x-3 items-center px-5 font-bold text-richblack-900 text-md'>
                <Link to={`/dashboard/recruiter/edit-job/${job?._id}`}>
                  <button className=' text-caribbeangreen-200 duration-100 p-1'>
                    Edit
                  </button>
                </Link>
                <button
                  onClick={e => {
                    e.preventDefault();
                    deleteJobById(job?._id)
                  }}
                className=' text-pink-300 duration-100 p-1'>
                  Delete
                </button>
              </div>
            }
              <BsFillDiagram3Fill/>
              <p>{job.noOfPositions}</p>
            </div>
          </div>
        </div>
      </Link>
  )
}
