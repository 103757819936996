import React, { useEffect } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { fetchAllWalkInDrives } from '../services/operations/JobAPI';
import { toDays } from '../utils/dateFormatter';
import Spinner from '../components/common/Spinner';
import { BiSolidDetail } from "react-icons/bi";
import Footer from '../components/common/Footer';

const WalkInDrives = () => {

    const {walkInId} = useParams();
    const [walkInDrives,setWalkInDrives] = React.useState([]);
    const [loading,setLoading] = React.useState(false);

    useEffect(()=>{
        getAllWalkInDrives();
    },[]);

    const getAllWalkInDrives = async()=>{
        setLoading(true);

        const result = await fetchAllWalkInDrives();
        if(result)
            setWalkInDrives(result);

        setLoading(false);
    }

    if(loading)
        return <Spinner/>
    
    if(loading && !walkInDrives)
        return <Spinner/>
    
    // console.log("Walk In",walkInDrives)
  return (
    <div className='flex-col w-screen'>
        <section className='w-11/12 max-w-maxContent mx-auto flex items-start justify-evenly gap-x-5 overflow-hidden sm:h-[80vh] h-full md:p-10'>
            
            {/* Drive List */}
            <div className={`${ !walkInId ? "block" : "hidden"} sm:block items-center overflow-y-auto profile sm:w-[45%] w-full h-full px-4`}>
                <div className='flex flex-col gap-10 px-3'>
                    <p className='text-3xl font-bold py-3 border-b w-full flex items-end justify-between'>
                        <span>Explore Now</span>
                        {/* <span className=' text-yellow-50 text-lg px-2 py-[2px] bg-yellow-5 bg-opacity-15  border-yellow-50 border-[.1px] rounded-full'>{walkInDrives && walkInDrives?.length}+</span> */}
                        <span className=' text-yellow-50 text-lg px-2 py-[2px] bg-yellow-5 bg-opacity-15  border-yellow-50 border-[.1px] rounded-full'>20+</span>
                    </p>

                    {/* Drive List Container */}
                    <div className='w-full flex flex-col items-stretch gap-8'>
                        {
                           !walkInDrives || !walkInDrives.length 
                           ? <p className='text-lg font-bold text-center'>No Walk-In Drives Available</p>
                           :  walkInDrives.map(wd=>(
                                <Link key={wd?._id} to={
                                        `/walk-in-drives/${wd?.category?.name?.toLowerCase().split(" ").join("-")
                                            .replace(/[^a-zA-Z0-9 -]/g,"")
                                            .replace(/-{2,}/g, "-")
                                            .replace(/^-|-$/g, "")
                                          }/${wd?._id}`
                                    }
                                    state={{walkInId: wd?._id}}
                                    // className='h-100vh'
                                >
                                    <div className={`border border-${walkInId === wd?._id ? "yellow-50" : "richblack-5"} 
                                    p-4 flex flex-col items-start rounded-md`}>
                                        <p className='flex items-center gap-x-2'>
                                            <span className='text-lg font-bold'>Roles - {wd?.roles[0]}</span>
                                            {
                                                wd?.roles?.length > 1 &&
                                                <span className=' text-yellow-50 text-sm'>+{wd?.roles?.length && wd?.roles?.length - 1} more</span>
                                            }
                                        </p>
                                        
                                        <p>
                                            <span className='font-bold'>Category - </span>
                                            <span>{wd?.category?.name}</span>
                                        </p>
                                    
                                        {
                                            toDays(new Date() , new Date(wd?.deadline))
                                            ? <span className=' text-caribbeangreen-200 text-xs px-2 py-[2px] bg-caribbeangreen-200 bg-opacity-15  border-caribbeangreen-200 border-[.1px] rounded-full mt-2'>
                                                Registration Closes in {toDays(new Date() , new Date(wd?.deadline))} days
                                                </span> 
                                            : <span className=' text-pink-300 text-xs px-2 py-[2px] bg-pink-300 bg-opacity-15  border-pink-300 border-[.1px] rounded-full mt-2'>
                                                Registration Closed
                                            </span> 
                                        }
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* Drive Details and Register for Drive  */}
            <div className={`${ walkInId ? "flex" : "hidden"} sm:flex px-6 py-2 overflow-y-scroll overflow-x-hidden sm:w-[50%] w-full min-h-[50%] h-full border-[.1px] justify-center items-center`}>
                {
                    walkInId 
                    ? <Outlet/>
                    : <div className='flex flex-col justify-center items-center gap-4 text-yellow-50 animate-pulse'>
                        <BiSolidDetail className='text-8xl' />
                        <p className=' text-3xl font-bold'>
                            Click on any drive and see details
                        </p>
                    </div>
                }
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default WalkInDrives